import { Button, Container, Flex, Link, Spacer, Text } from '@chakra-ui/react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { H1 } from '../components/styles/HeadingStyles';
import ManualPageWrapper from '../components/ui/ManualPageWrapper';

type Props = {
  data: {
    id: string;
    url: string;
    image: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const pageNotFound = ({ data }: Props) => (
  <ManualPageWrapper
    seoTitle="404 - Whoops. Page not found"
    leftUtilitySection={
      <Container maxW="container.xl">
        <Flex wrap="wrap">
          <div>
            <H1>Whoops!</H1>
            <H1 pb={['4', '8']}>Page not found</H1>
            <Text fontSize={['xl', '2xl']} pb={['2', '8']}>
              Not sure how you got here...
            </Text>
            <Button onClick={() => navigate(-1)} variant="ghost" mr="4">
              Go Back
            </Button>
            <Button variant="primary">
              <Link textDecoration="none" href="/">
                Go to Homepage
              </Link>
            </Button>
          </div>
          <Spacer />
          <div>
            <GatsbyImage image={data.image.gatsbyImageData} alt={data.id} />
          </div>
        </Flex>
      </Container>
    }
  />
);

export default pageNotFound;

export const query = graphql`
  query ImageQuery {
    image: datoCmsAsset(filename: { eq: "404-01.png" }) {
      id
      url
      gatsbyImageData(width: 700)
    }
  }
`;
